<template>
  <v-dialog
    v-model="dailog"
    width="570"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left>
          mdi-book-edit
        </v-icon>New Booking
        <v-spacer />
        <v-btn
          color="success"
          text
          dense
          @click="emitToParent('close')"
        >
          Close
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form  
              
              ref="form"
              v-model="valid"
              lazy-validation
            >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="booking.class"
                :rules="nameRules"
                :items="['DOMESTIC', 'FOREIGN']"
                label="Classification"
                dense
                outlined
              />
              Room Details:
              <v-divider class="mb-4"/>
              <v-text-field
                v-model="booking.no_of_guest"
                :rules="nameRules"
                dense
                outlined
                label="No. of guest"
                type="number"
              />
              <!-- <v-text-field
              dense
                outlined
                label="No. of rooms occupied"
                type="number"
              />
              <v-text-field
              dense
                outlined
                hint="Ex. 101,102"
                label="Booked room numbers"
              /> -->
              <v-row>
                <v-col cols="6">
                  <v-menu
                        v-model="from_menu"
                        :rules="nameRules"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Checkin Date"
                                 :value="from_date"
                                 :rules="nameRules"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date"   no-title scrollable @input="from_menu=false" />
                    </v-menu>

                </v-col>
                <v-col cols="6">

                  <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="Checkout Date"
                                 :value="to_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false" />
                    </v-menu>
                </v-col>
              </v-row>

              Primary Contact:
              <v-divider class="mb-4"/>

              <v-text-field
               v-model="booking.contact_person.name"
                :rules="nameRules"  
              dense
                outlined
                label="Full Name"
              />
              <v-text-field
              v-model="booking.contact_person.number"
                :rules="nameRules"  
              dense
                outlined
                label="Contact No."
              />
              <v-text-field
              v-model="booking.contact_person.email"
              dense
                outlined
                label="Email Address"
              />
            </v-col>
            
          </v-row>
        </v-form>
      </v-card-text>

        <v-card-actions>
          <v-btn  tile color="warning"    @click="emitToParent('close')">Close</v-btn>
          <v-spacer/>
          <v-btn  tile color="success" :loading="loading" @click="validate">Add Booking</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    hotel: {
      type: Number,
      default: 0
    }, 
    
    },
    data: () => ({
      valid: true,
      loading: false,
        dailog: false,
        booking: { contact_person: {}}, 
        nameRules: [
        v => !!v || 'Field is required'
      ],
        from_menu: false,
        to_menu: false,
        from_date: "",
        to_date: "",
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
        }
    },
    created() {
     
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      validate () {
        if (this.$refs.form.validate()) {
          this.addBooking()
        }
      },
      addBooking() {
        this.loading = true
        this.booking.hotel_id = this.$IsNum(this.hotel)
        this.booking.checkin_date =  this.from_date
        this.booking.no_of_guest =  this.$IsNum(this.booking.no_of_guest)
        this.booking.checkout_date =  this.to_date
        console.log(this.booking)

       this.$http.post('booking/new', this.booking).then(response => {
            this.loading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           
            response.data.status? this.emitToParent( response.data.booking): this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      } 
    },
  }
</script>
