<template>
  <v-app-bar
    v-model="appbar"

    elevation="0"
    color="white"
    app
    class="font-weight-light"
  >
<img
:src="$store.state.systemlogo"  
width="80px"
>
    <v-toolbar-title class="text-primary">
      {{ $route.name }}
    </v-toolbar-title>
    
  </v-app-bar>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      confirm: false,
      bar_color: "secondary"
    }),
    computed: {
      appbar() {
        return this.$store.state.appbar
      }
    },
    methods: { 
      ...mapMutations(['setLoggedIn', 'setAppBar', 'setDrawer']),
      drawer() {
        this.$store.state.drawer != true?this.setDrawer(true):this.setDrawer(false)
        this.bar_color != "primary"?this.bar_color="primary":this.bar_color="secondary"
      },
      logout() {
        this.setDrawer(false)
        this.setAppBar(false)
         this.setLoggedIn(false)
         this.$session.destroy(),
         this.$router.push('/')
      }

    }
  }
</script>