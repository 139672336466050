
export default {
    drawer: false, 
    appbar: false, 
    responsive: false,
    systemlogo: '../logo.png',
    app_icon: '../icon.png',
    applogo: '../logo2.png',
    login_bg: '../img/hotels.jpeg',
    seal: '../img/seal.png',
    gcash: '../img/gcash.png',
    dot: '../img/dot.png',
    is_logged: false,
    show_alert: false,
    alert_color: "secondary",
    alert_text: "No message"
  }
  