<template>
  <v-navigation-drawer
    v-if="$store.state.is_logged"
    v-model="$store.state.drawer"
    color="secondary"
    dark
    app
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="$store.state.app_icon" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>Administrator</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list class="grow">
      <template v-for="(link,i) in links">
        <template v-if="!link.child">
          <v-list-item
            :key="i"
            @click="$router.push(link.route).catch(err => {})"
          >
            <v-list-item-title>
              <v-icon left>
                {{ link.icon }}
              </v-icon> {{ link.label }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-else>
          <v-menu
            :key="i"
            transition="slide-x-transition"
            bottom
            right
            :offset-x="offset"
            rounded="0"
          >
            <template #activator="{ on, attrs }">
              <v-list-item
                :key="i"
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>
                  <v-icon left>
                    {{ link.icon }}
                  </v-icon> 
                  <span>{{ link.label }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list
              color="#D4AD36"
              dark
            >
              <v-list-item
                v-for="(item, index) in link.childs"
                :key="index"
                @click="$router.push(item.route).catch(err => {})"
              > 
                <v-list-item-title>
                  <v-icon left>
                    {{ item.icon }}
                  </v-icon> {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  export default {
    data () {
      return {
          offset: true,
        links: [
          {
            label: 'Main', icon: 'mdi-home', route: '/main', child: false,
          },
          // {
          //   label: 'Inventory', icon: 'mdi-clipboard-list-outline', route: '/inventory', child: true,
          //   childs: [
          //     {
          //       label: 'Products Management', icon: 'mdi-format-list-checks', route: '/inventory',
          //     },
          //     {
          //       label: 'Stocks Management', icon: 'mdi-cube', route: '/inventory',
          //     },
          //     {
          //       label: 'Categories', icon: 'mdi-archive-cog', route: '/inventory',
          //     },
          //   ],
          // },
           {
            label: 'Bookings', icon: 'mdi-receipt', route: '/bookings', child: false,
          },
          {
            label: 'Guest List', icon: 'mdi-account-group', route: '/guest-list', child: false,
          },
          {
            label: 'Accounts', icon: 'mdi-account-cog', route: '/account', child: false,
          },
          //  {
          //   label: 'Settings', icon: 'mdi-cogs', route: '/main', child: false,
          // },
        ],
      }
    },
  }
</script>