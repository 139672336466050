/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/easy',
      name: 'Easy Access',
      view: 'Easy'
    },
    {
      path: '/confirm',
      name: 'Confirm Payment',
      view: 'Confirm'
    },
    {
      path: '/main',
      name: 'Main',
      view: 'Main'
    },
    {
      path: '/registration',
      name: 'Registration',
      view: 'RegistrationPage'
    },
    {
      path: '/verify',
      name: 'Verification',
      view: 'VerifyPage'
    },
    {
      path: '/bookings',
      name: 'Booking Management',
      view: 'BookingPage'
    },
    {
      path: '/details',
      name: 'Details',
      view: 'Details'
    },
    {
      path: '/payment',
      name: 'Payment',
      view: 'Payment'
    },
    {
      path: '/qr-code',
      name: 'Booking QRcode',
      view: 'Qrcode'
    },
    {
      path: '/cancelled',
      name: 'Payment Cancelled',
      view: 'Cancelled'
    },
    {
      path: '/complete',
      name: 'Payment Complete',
      view: 'Complete'
    },
    {
      path: '/account',
      name: 'Account Management',
      view: 'AccountPage'
    },
  
  ]
  