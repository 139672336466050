<template>
  <v-dialog
    v-model="dailog"
    fullscreen
    persistent
  >
  <div class="bg-top">
          <v-row no-gutters>
            <v-col cols="12" class="text-center">
              <p class="mt-4">
              <span class="text-h2 font-weight-thin text-white">SCAN ME</span>
            </p>
              <div style="margin-top: 100px">
                <div style="width: 276px; margin:auto; position: relative; background-color: #fff; border-radius: 12px; padding:10px;">
                  <div class="_syslogo"></div>
                 
                  <qr-code :text="guest.id+'::'+guest.ref_no" :size="256" ></qr-code>
                  
               
                </div>
            </div> 
            <div>
                <span class="font-weight-thin text-center mt-4 font-italic">
                {{guest.lastname + ', ' + guest.firstname + ' ('+guest.age+')' + ' - ' +guest.gender + ' - ' +guest.country}} 
              </span>
              <br/>
              <span class="font-weight-light text-center text-info">
                PORT ASSIGNMENT: {{PORT}}
              </span>
              <p class=" text-center text-warning font-italic font-weight-thin">
                Note: Port assignment may change anytime.
              </p>
              <p class="text-center">
                <v-btn elevation="0"  rounded color="#243C57" dark @click="emitToParent('close')">DONE</v-btn>
              </p>
            </div>
            </v-col>
          </v-row>

            
    </div>
  
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () =>{}
    },
    booking: {
      type: Object,
      default: () =>{}
    },
    state: {
      type: String,
      default: ""
    }
    
    },
    data: () => ({
        valid: true,
        valid2: true,
        loading: false,
        gloading: false,
        dailog: false,
        from_menu: false,
        to_menu: false,
        guests: [],
        guest:{},
        settings:[],
        from_date: "",
        to_date: "",
        nameRules: [
        v => !!v || 'Field is required'
      ],
      guestRules: [
        v => !!v || 'Field is required'
      ],
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.guest = this.data
            this.getSettings()
          } else {
            this.guest = {}
          }
        }
    },
    created() {
    },
    computed: {
      countries() {
        return this.$countries
      },
      PORT() {
        var port = this.settings.reduce((res,item)=>{
            if(item.name == 'Port') {
              res = item.value
            }
            return res
        },'')
        return port
       },
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      getSettings() {
        this.$http.post("settings/get",{}).then(response => {
          console.log( response.data)
          response.data.status? this.settings = response.data.settings:this.settings = []
          }).catch(e => {
              this.NETWORK_ERROR(e)
         })
      },
      setCountry(){
        console.log(this.guests.class)
        if(this.guests.class != "FOREIGN") {
          this.guests.country = 'Philippines'
        } else {
          this.guests.country = ""
        }
      
      },
     
      validateGuest() {
        if (this.$refs.form2.validate()) {
          if(this.state == "new") {
            this.addGuest()
          } else {
            this.updateGuest()
          }
          
        }
      },
     
      guestList() {
        this.$http.post('booking/guests', {booking_id:this.$IsNum(this.booking.id)}).then(response => {
            response.data.guests? this.guests = response.data.guests:this.guests = []
            console.log(response.data)
        }).catch(e => {
        console.log(e)
        })
      }, 
      clearForm(){
        this.$refs.form2.reset()
        this.guest = {}
        
      },
      addGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.guest.booking_id = this.$IsNum(this.booking.id)
        this.guest.hotel_id = this.$IsNum(this.booking.hotel_id)
        this.guest.ref_no = this.booking.ref_no
        this.$http.post('guest/new', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           // this.clearForm()
            this.guest.firstname = ""
            this.guest.lastname = ""
            this.guest.age = ""
            this.guest.gender = ""
            this.guest.pwd = ""
            this.guest.senior = ""
            this.valid2 = true
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
      updateGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.$http.post('guest/update', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.clearForm()
     
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
      removeGuest(guest) {
       console.log(guest)
        this.$http.post('guest/remove', guest).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           // this.guestList() 
          
        }).catch(e => {
        console.log(e)
        })
      }
    },
  }
</script>
